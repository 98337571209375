const TermsAndCondView = {template:`<termsAndConditions></termsAndConditions>`};

const WhyUsView = {template:`<whyUsPage></whyUsPage>`};

const PrivacyPolicyView = {template:`<privacyPolicy></privacyPolicy>`};

const PhonesView = {template:`<phonesPage></phonesPage>`};

const ContactUsView = {template:`<contactUs></contactUs>`};

globalRouter.splice(globalRouter.length-2, 0, {name: 'TermsAndCondView', path: '/terms', component: TermsAndCondView});
globalRouter.splice(globalRouter.length-2, 0, {name: 'WhyUsView', path: '/why', component: WhyUsView},);
globalRouter.splice(globalRouter.length-2, 0, {name: 'PrivacyPolicyView', path: '/privacy', component: PrivacyPolicyView});
globalRouter.splice(globalRouter.length-2, 0,  {name: 'PhonesView', path: '/phones', component: PhonesView});
globalRouter.splice(globalRouter.length-2, 0, {name: 'ContactUsView', path: '/contact', component: ContactUsView});

console.log("HELLO ROUTER OFIDIRECT");
